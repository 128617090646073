export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL';
export const ORDER_DETAILS_RESET = 'ORDER_DETAILS_RESET';

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL';
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET';

export const MY_ORDERS_REQUEST = 'MY_ORDERS_REQUEST';
export const MY_ORDERS_SUCCESS = 'MY_ORDERS_SUCCESS';
export const MY_ORDERS_FAIL = 'MY_ORDERS_FAIL';
export const MY_ORDERS_RESET = 'MY_ORDERS_RESET';

export const ALL_ORDERS_REQUEST = 'ALL_ORDERS_REQUEST';
export const ALL_ORDERS_SUCCESS = 'ALL_ORDERS_SUCCESS';
export const ALL_ORDERS_FAIL = 'ALL_ORDERS_FAIL';
export const ALL_ORDERS_RESET = 'ALL_ORDERS_RESET';

export const ORDER_DELIVERED_REQUEST = 'ORDER_DELIVERED_REQUEST';
export const ORDER_DELIVERED_SUCCESS = 'ORDER_DELIVERED_SUCCESS';
export const ORDER_DELIVERED_FAIL = 'ORDER_DELIVERED_FAIL';
export const ORDER_DELIVERED_RESET = 'ORDER_DELIVERED_RESET';
