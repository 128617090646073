import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
const SearchBox = ({ history }) => {
  const [keyword, setKeyword] = useState('');

  const submitHandler = (e) => {
    e.preventDefault();

    if (keyword.trim()) {
      history.push(`/search/${keyword}`);
    } else {
      history.push(``);
    }
  };
  return (
    <div>
      <Form
        onSubmit={submitHandler}
        inline
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <Form.Control
          style={{ marginRight: '10px' }}
          type='text'
          name='q'
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          placeholder='Search Products'
          className='mr-sm-2 ml-sm-5'
        ></Form.Control>
        <Button type='submit' className='py-2  keikei_button search_button'>
          Search
        </Button>
      </Form>
    </div>
  );
};

export default SearchBox;
