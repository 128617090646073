import React from 'react';
import { Link } from 'react-router-dom';

const SwiperSlide = ({ title, description, to, price, style }) => {
  return (
    <div className='swiper-slide'>
      <div className='swiper-image swiper-image' style={style}></div>
      <div className='overlay'></div>
      <div
        className='container'
        style={{
          height: '100%',
        }}
      >
        <div className='content-wrapper'>
          <div className='content'>
            <h1>{title}</h1>
            <p>{description}</p>
            <p>${price} </p>
            <Link to={to} className='button keikei_button'>
              Buy Now
            </Link>
          </div>
          {/* <!-- If we need navigation buttons --> */}
          <div className='swiper-nav-wrapper'>
            <div className='swiper-button-prev '></div>
            <div className='swiper-button-next '></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwiperSlide;
