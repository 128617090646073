import React, { useRef, useEffect, useState } from 'react';
import gsap from 'gsap';
// import { Link } from 'react-router-dom';

const messages = [
  'Free candle on orders $250+ No code needed! Offers >',
  'Free shipping on orders $75+ Offers >',
  // 'MESSAGE THREE',
];
const Toast = () => {
  const boxRef = useRef();
  const [tl] = useState(() => gsap.timeline({ repeat: -1, repeatDelay: 0 }));

  useEffect(() => {
    let counter = 0;
    const onComplete = () => {
      counter = counter !== messages.length - 1 ? counter + 1 : 0;
      boxRef.current.innerHTML = messages[counter];
    };

    const animation1 = tl.fromTo(
      boxRef.current,
      {
        opacity: 0,
        // ease: 'bounce.fadeIn'
      },
      {
        opacity: 1,
        // ease: 'bounce.easeOut',
        duration: 5,

        onComplete: () => {
          onComplete();
        },
      }
    );

    return () => {
      animation1.kill();
      //   animation2.scrollTrigger.kill();
    };
  }, [tl]);

  return (
    <div className='notification_box '>
      <p to='' ref={boxRef}>
        {messages[0]}
      </p>
    </div>
  );
};

export default Toast;
