import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import Rating from './Rating';

const Product = ({ product }) => {
  return (
    <Card className='my-3 rounded product_card_container'>
      <Link to={`/product/${product._id}`}>
        <div
          className='product_image'
          style={{
            backgroundImage: `url(${product.image})`,
          }}
        ></div>
        {/* <Card.Img src={product.image} variant='top'></Card.Img> */}
      </Link>
      <Card.Body>
        <Link to={`/product/${product._id}`}>
          <Card.Title as='div'>
            <strong>{product.name}</strong>
          </Card.Title>
        </Link>
        <Card.Text as='div'>
          {/* <Rating
            value={product.rating}
            text={`${product.numReviews} reviews`}
          ></Rating> */}
          {/* <div className='my-3'>
            {product.rating} from {product.numReviews} reviews
          </div> */}
        </Card.Text>
        <Card.Text as='h3'>
          <div className='my-0'>${product.price}</div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Product;
