import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwiperSlide from './SwiperSlide';
import { listTopProducts } from '../redux/actions/productActions';
import Loader from './Loader';
import Message from './Message';

const swiper = './swiper.js';

const SwiperThing = () => {
  const dispatch = useDispatch();
  const topProducts = useSelector((state) => state.topProducts);
  const { loading, error, products } = topProducts;
  useEffect(() => {
    dispatch(listTopProducts());
  }, [dispatch]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = swiper;
    script.async = true;
    script.id = 'swiper-script';
    document.body.appendChild(script);

    return () => {
      const nodeList = Array.from(document.scripts);
      const foundScript = nodeList.find((s) => s.id === 'swiper-script');
      foundScript.parentNode.removeChild(foundScript);
    };
  }, [products]);

  return loading ? (
    <Loader></Loader>
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <div className='swiper' style={{ maxHeight: '700px' }}>
      <div className='swiper-wrapper swiper-image-bottom'>
        {products.length &&
          products.map((product) => {
            const productImage =
              product.featuredImage && product.featuredImage.length
                ? product.featuredImage.replace('\\', '/')
                : product.image.replace('\\', '/');
            return (
              <SwiperSlide
                key={product._id}
                to={`/product/${product._id}`}
                title={product.name}
                description={product.description}
                price={product.price}
                style={{
                  backgroundImage: `url(${productImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SwiperThing;
